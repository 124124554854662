import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";
import Icons from "../../components/icons";

export default function MT5AccountCard({
  AcInfoClick,
  AcRenameClick,
  AcPasswordClick,
  AcArchiveClick,
  AcReactivateClick,
  AcLeverageClick,
  AcHistoryClick,
  AcTradeClick,
  AcCredentialClick,
  data,
  currencyInrRate,
  caption = null
}) {
  const { apiCall } = useApi();
  const { state } = useAuth();
  const token = state.token || "";

  const [balance, setBalance] = useState(data.available_balance);
  // const [currencyInrRate, setCurrencyInrRate] = useState(1);
  const [currency, setCurrency] = useState("USD");
  const [isCurrencyDropActive, setIsCurrencyDropActive] = useState(false);
  const [isCommonDropActive, setIsCommonDropActive] = useState(false);
  const [currentPl, setCurrentPl] = useState('');
  const [currentPlColor, setCurrentPlColor] = useState('');
  //const [currencyInrRate, setCurrencyInrRate] = useState(1);
  // Open/Close Currency Dropdown
  const handleCurrencyOpen = () => setIsCurrencyDropActive(true);
  const handleCurrencyClose = () => setIsCurrencyDropActive(false);
  
  const handleCurrencySelect = (selectedCurrency) => {
    if(currency != selectedCurrency){
      if (selectedCurrency === "INR") {
        setBalance(balance * currencyInrRate);
      }if (selectedCurrency === "USD") {
        setBalance(balance / currencyInrRate);
      }
      setCurrency(selectedCurrency);
    setIsCurrencyDropActive(false);
    }
    
  };

  useEffect(() => {
    getCurrentPL();

    const intervalId = setInterval(() => {
      getCurrentPL();
    }, 300000); // 300000 ms = 5 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const getCurrentPL = async () => {
    // Logic to get user wallet group-wise data
    try {
      // setIsLoading(true);
      const response = await apiCall(API_URL +'get-current-pl', {
          user_id: state.userData.id,
          token: token,
          user_wallet_id:data.id
          //transfer_type:transferTypeFilter,
         // transaction_status:statusFilter
      });
      if (response.data.success == '0') {
// console.log('response.data',response.data.data);

        setCurrentPl(response.data.data.currentPL);
        setCurrentPlColor(response.data.data.color);
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  // Open/Close Common Dropdown
  const handleCommonOpen = () => setIsCommonDropActive(true);
  const handleCommonClose = () => setIsCommonDropActive(false);
 
  

  // useEffect(() => {
  //   if (currency === "INR") {
  //     setBalance(balance * currencyInrRate);
  //   }if (currency === "USD") {
  //     setBalance(balance / currencyInrRate);
  //   }
  // }, [currency]);
// console.log(caption);

  return (
    <div className="col-lg-6 col-sm-6">
      <div className="mt5-account-card">
        <div className="mt5-ac-inner">
          <div className="mt5-tags-bx">
            <div className="mt5-tag-item mt5-current">{data.mt5_type}</div>
            {(data.mt5_type == 'real') && 
              <div className="mt5-tag-item">{data.account_type.name}</div>
            }
            <div className="mt5-tag-item">MT5</div>
            {data.account_number !== data.code && (
              <div className="mt5-tag-item">{data.code}</div>
            )}
          </div>

          <div className="mt5-id">
            {data.account_number}
          </div>
          <div className="mt5-other-data">
            <div className="mt5-balances d-flex flex-wrap gap-0.5 align-items-end">
              {balance.toFixed(2)}
              <div
                className={`common-drop-item cdi-currency ${isCurrencyDropActive ? 'active' : ''}`}
                onMouseLeave={handleCurrencyClose}
              >
                <div className="selected-item" onClick={handleCurrencyOpen}>
                  <div className="si-data-bx">
                    <div className="si-d-heading">{currency}</div>
                    <div className="si-down-icon"><Icons.DownSvg /></div>
                  </div>
                </div>

                <div className="common-drop-bx">
                  <div className="common-drop-inner">
                    <div
                      className={`selecte-option-item ${currency === "USD" ? "selected" : ""}`}
                      onClick={() => handleCurrencySelect("USD")}
                    >
                      <div className="soi-data-bx">
                        <div className="soi-d-heading">USD</div>
                      </div>
                    </div>

                    <div
                      className={`selecte-option-item ${currency === "INR" ? "selected" : ""}`}
                      onClick={() => handleCurrencySelect("INR")}
                    >
                      <div className="soi-data-bx">
                        <div className="soi-d-heading">INR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt5-od-item">Current P&L<span className={currentPlColor == 'red' ? 'color-red' : 'color-green'}>{currentPl}</span></div>
          </div>

          <div className="mt5-other-data">
              <div className="mt5-od-item">Equity<span>{data.equity.toFixed(2)}</span></div>
              <div className="mt5-od-item">Leverage<span>{data.leverage.value}x</span></div>
          </div>

          {!data.archived && (
            <div className="mt5-btns-bx">
              {(data.mt5_type == "real") && (<>
                <Link to={`/deposit/mt5/${data.id}`} className="mt5-btn-item">
                  <div className="mt5-bi-icon">
                    <Icons.DepositSvg />
                  </div>
                  <div className="mt5-bi-text">Deposit</div>
                </Link>
              </>)}
              {(caption && caption === "1" && data.mt5_type == "real") && (
                <>
                <Link to={`/withdraw/mt5/${data.id}`} className="mt5-btn-item">
                  <div className="mt5-bi-icon">
                    <Icons.WithdrawSvg />
                  </div>
                  <div className="mt5-bi-text">Withdraw</div>
                </Link>
                </>
              )}
              {/* <div onClick={AcTradeClick} className="mt5-btn-item mt5-trade">
                <div className="mt5-bi-icon">
                  <Icons.TradeSvg />
                </div>
                <div className="mt5-bi-text">Trade</div>
              </div> */}

              <div onClick={AcCredentialClick} className="mt5-btn-item mt5-trade">
                <div className="mt5-bi-icon">
                  <Icons.KeySvg />
                </div>
                <div className="mt5-bi-text">Credentials</div>
              </div>
            </div>
          )}

          {data.archived && (
            <div className="mt5-archived-bx">
              <div className="archived-info">Account is archived due to inactivity</div>
              <div className="common-btn-item cbi-small cbi-fill" onClick={AcReactivateClick}>
                <span>Reactivate</span>
              </div>
            </div>
          )}

          {!data.archived && (
            <div
              className={`common-drop-item cdi-absolute ${isCommonDropActive ? 'active' : ''}`}
              onMouseLeave={handleCommonClose}
            >
              <div className="mt5-drop-icon" onClick={handleCommonOpen}>
                <Icons.List2Svg />
              </div>
              <div className="common-drop-bx">
                <div className="common-drop-inner">
                  <div className="selecte-option-item" onClick={AcInfoClick}>
                    Account Information
                  </div>
                  <div className="selecte-option-item" onClick={AcRenameClick}>
                    Rename Name
                  </div>
                  <div className="selecte-option-item" onClick={AcPasswordClick}>
                    Change Password
                  </div>
                  <div className="selecte-option-item d-none" onClick={AcLeverageClick}>
                    Change Leverage
                  </div>
                  <div className="selecte-option-item" onClick={AcHistoryClick}>
                    Trade History
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
