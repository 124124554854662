import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeModeContext = createContext();

export const ThemeModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check local storage for the theme preference
    if (typeof window !== 'undefined') {
      const savedTheme = localStorage.getItem('theme');
      return savedTheme ? savedTheme === 'dark' : true;
    }
    return true; // Default to dark mode
  });

  const noTransition = () => {
    if (typeof document !== 'undefined') {
      document.querySelectorAll('*').forEach((el) => {
        el.classList.add('no-transition');
      });
      setTimeout(() => {
        document.querySelectorAll('*').forEach((el) => {
          el.classList.remove('no-transition');
        });
      }, 500); // 0.5 seconds (reduce based on UX)
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const themeColorMeta = document.querySelector('meta[name="theme-color"]');
      if (isDarkMode) {
        document.documentElement.setAttribute('data-theme', 'dark-theme');
        if (themeColorMeta) themeColorMeta.setAttribute('content', '#0D1721');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.setAttribute('data-theme', 'light-theme');
        if (themeColorMeta) themeColorMeta.setAttribute('content', '#ffffff');
        localStorage.setItem('theme', 'light');
      }
    }
  }, [isDarkMode]);

  const toggleTheme = () => {
    noTransition();
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeModeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeModeContext.Provider>
  );
};

export const ThemeMode = () => {
  const context = useContext(ThemeModeContext);
  if (!context) {
    throw new Error('ThemeMode must be used within a ThemeModeProvider');
  }
  return context;
};
