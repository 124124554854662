import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import BannerImg from "../../components/BannerImg";
import API_URL from "../../config/config";
import WalletBalances from "../PageWallet/WalletBalances";
import VerificationStatus2 from "../VerificationStatus2";
import RelationshipManager from "../RelationshipManager";
import SummaryData from "../SummaryData";
// import Announcements from "../../components/Announcements";

export default function PageDashboard() {
  const [bannerImg, setBannerImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const { state } = useAuth();

  const { apiCall } = useApi(); // Use the apiCall function
  const fetchBanner = async () => {
    
    try {

      const response = await apiCall(`${API_URL}get-banner`, {});

      if (response.data.success) {
        // setBannerImg(response.data.data.image_url);
        setBannerImg({
          imageUrl: response.data.data.image_url,
          link: response.data.data.link
        });
      } else {
        console.error(
          "API Response error:",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching banner:", error.message || error);
    } finally {
      setLoading(false);
    }
  };
  const isCurrencyRatesFetched = useRef(false);
  useEffect(() => {
    if (!isCurrencyRatesFetched.current) {
    fetchBanner();
    isCurrencyRatesFetched.current = true;
    }
  }, []);

  return (
    <>
      <>
        <div className="container-lg pt-4 pb-5 px-lg-4">
          <div className="row row-gap-4">
            <div className="col-12">
              {loading ? (
                <div className="skeletant-bx skeletant-design sh-95"></div>
              ) : bannerImg ? (
                // <BannerImg src={bannerImg} />
                <BannerImg src={bannerImg.imageUrl} link={bannerImg.link} />
              ) : (
                <p></p>
              )}
            </div>

            <div className="col-md-6">
              <WalletBalances />
            </div>

            <div className="col-md-6">
              {/* <VerificationStatus /> */}
              <VerificationStatus2 className="vs-fd-column" />
            </div>

            <div className="col-12">
              <SummaryData />
            </div>

            {/* {state?.userData?.manager_info && 
              <div className="col-12">
                  <RelationshipManager data={state?.userData?.manager_info}/>
              </div>
            } */}

            {/* <div className="col-lg-6">
              <WalletsOverview />
            </div> */}
          </div>
        </div>

        {/* <Announcements /> */}
      </>
    </>
  );
}
